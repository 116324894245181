<template>
  <div class="voxel-library view">
    <app-header title="Ingame - VoxelLibrary"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="main-content-padding">
      <div class="grid-table" :style="{'grid-template-columns': `auto ${voxelGroups.map(g => '1fr').join(' ')}`}">
        <div class="grid-table__cell grid-table__cell--sticky text--center grid-table__cell--clickable"
             @click="changeSorting('name')" title="Sort by name">
          <p>{{__('valuesForOneQubicMeter')}}</p>
          <p class="text--teal">{{__('clickForSortByRow')}}</p>
        </div>
        <div v-for="voxelGroup in sortedVoxelGroups"
            class="grid-table__cell grid-table__cell--sticky text--center"
        >
          <img :src="voxelGroup.icon"/>
          <div>{{ voxelGroup.name.replace(' Honeycomb', '') }}</div>
        </div>

        <template v-for="property in properties">
          <div class="grid-table__cell grid-table__cell--sticky-left grid-table__cell--clickable"
               :style="{'min-width': '300px'}"
               @click="changeSorting(property)"
               :title="`Sort by ${property}`"
          >
            {{__(property)}}
          </div>
          <div v-for="voxelGroup in sortedVoxelGroups"
              class="grid-table__cell text--center"
              :class="getCellClasses(voxelGroup, property)"
          >
            <template v-if="property === 'tier'">
              <div class="skewed-ribbon skewed-ribbon--large">
                <div class="skewed-ribbon__item skewed-ribbon__item--info" :title="'Tier'">
                  {{ voxelGroup.tier }}
                </div>
              </div>
            </template>
            <template v-else-if="property === 'craftingTime'">{{ displayTime(voxelGroup[property]) }}</template>
            <template v-else-if="property === 'mass'">{{ numberToLocaleString(voxelGroup[property],2,2) }}</template>

            <template v-else-if="property === 'resistancesAntimatter'">{{ numberToLocaleString(voxelGroup[property],0,5) }}</template>
            <template v-else-if="property === 'resistancesElectromagnetic'">{{ numberToLocaleString(voxelGroup[property],0,5) }}</template>
            <template v-else-if="property === 'resistancesKinetic'">{{ numberToLocaleString(voxelGroup[property],0,5) }}</template>
            <template v-else-if="property === 'resistancesThermic'">{{ numberToLocaleString(voxelGroup[property],0,5) }}</template>

            <template v-else-if="property === 'resistancesAntimatterHp'">{{ numberToLocaleString(voxelGroup[property],0,0) }}</template>
            <template v-else-if="property === 'resistancesElectromagneticHp'">{{ numberToLocaleString(voxelGroup[property],0,0) }}</template>
            <template v-else-if="property === 'resistancesKineticHp'">{{ numberToLocaleString(voxelGroup[property],0,0) }}</template>
            <template v-else-if="property === 'resistancesThermicHp'">{{ numberToLocaleString(voxelGroup[property],0,0) }}</template>

            <template v-else-if="property === 'resistancesAntimatterHpPerMass'">{{ numberToLocaleString(voxelGroup[property],0,0) }}</template>
            <template v-else-if="property === 'resistancesElectromagneticHpPerMass'">{{ numberToLocaleString(voxelGroup[property],0,0) }}</template>
            <template v-else-if="property === 'resistancesKineticHpPerMass'">{{ numberToLocaleString(voxelGroup[property],0,0) }}</template>
            <template v-else-if="property === 'resistancesThermicHpPerMass'">{{ numberToLocaleString(voxelGroup[property],0,0) }}</template>
            <template v-else>{{ voxelGroup[property] }}</template>
          </div>
        </template>
      </div>
<!--      <div class="grid-table mt" :style="{'grid-template-columns': `auto ${voxelGroups.map(g => '1fr').join(' ')}`}">-->

<!--        <div class="grid-table__cell grid-table__cell&#45;&#45;sticky text--center">{{__('valuesForOneQubicMeter')}}</div>-->
<!--        <div v-for="voxelGroup in voxelGroups"-->
<!--             class="grid-table__cell grid-table__cell&#45;&#45;sticky text--center"-->
<!--        >-->
<!--          <img :src="voxelGroup.icon"/>-->
<!--          <div>{{ voxelGroup.name.replace(' Honeycomb', '') }}</div>-->
<!--        </div>-->

<!--        <template v-for="color in colors">-->
<!--          <div class="grid-table__cell grid-table__cell&#45;&#45;sticky-left">{{ color }}</div>-->
<!--            <div v-for="voxelGroup in voxelGroups"-->
<!--                class="grid-table__cell grid-table__cell&#45;&#45;zoom-img text--center"-->
<!--                v-html="tryGetVoxelIconForColor(voxelGroup.voxels, color)"-->
<!--                :title="voxelGroup.name.replace(' Honeycomb', '')"-->
<!--            >-->
<!--            </div>-->
<!--        </template>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
  import apiSecured from '../../api/secured';
  import AppHeader from '../../components/AppHeader';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { pageTitleMixin } from "../../mixins/pageTitle";
  import duration from "format-duration";

  export default {
    mixins: [notificationsMixin, localizationMixin, pageTitleMixin, localizationMixin],
    components: {
      AppHeader
    },
    data: () => ({
      loading: false,
      voxelGroup: null,
      subGroups: [],
      voxelGroups: [],
      voxels: [],
      properties: [
        'tier',
        'craftingTime',
        'hitpoints',
        'mass',
        'resistancesAntimatter',
        'resistancesElectromagnetic',
        'resistancesKinetic',
        'resistancesThermic',
        'resistancesAntimatterHp',
        'resistancesElectromagneticHp',
        'resistancesKineticHp',
        'resistancesThermicHp',
        'resistancesAntimatterHpPerMass',
        'resistancesElectromagneticHpPerMass',
        'resistancesKineticHpPerMass',
        'resistancesThermicHpPerMass'
      ],
      values: {},
      colors: [],
      sortResultsBy: 'name',
      sortDesc: false,
    }),
    computed: {
      sortedVoxelGroups() {
        return this.voxelGroups.sort((a, b) => {
          if(this.sortDesc) {
            return a[this.sortResultsBy] > b[this.sortResultsBy] ? -1 : 1;
          }
          return a[this.sortResultsBy] > b[this.sortResultsBy] ? 1 : -1;
        });
      }
    },
    methods: {
      changeSorting(property) {
        if (this.sortResultsBy === property) {
          this.sortDesc = !this.sortDesc;
        } else {
          this.sortResultsBy = property;
        }
      },
      numberToLocaleString(value, minFractions = 0, maxFractions = 17) {
        if (value === null || value === undefined) {
          return 'NULL';
        }
        return value.toLocaleString(undefined, { minimumFractionDigits: minFractions, maximumFractionDigits: maxFractions });
      },
      displayTime(seconds) {
        return duration(seconds * 1000);
      },
      tryGetVoxelIconForColor(voxels, color) {
        const found = voxels.find(voxel => voxel.name.includes(color));
        if(found) {
          return `<img src="${found.icon}"/>`
        }
        return '';
      },
      getCellClasses(voxelGroup, property) {
        const sorted = this.values[property].sort((a, b) => a > b ? 1 : -1);
        const min = sorted[0];
        const max = sorted[sorted.length - 1];
        const percent = (voxelGroup[property] - min) * 100 / (max - min);
        switch (property) {
          case 'craftingTime':
          case 'mass':
            return {
              'text--info': voxelGroup[property] === min,
              'text--success': percent < 25 && voxelGroup[property] !== min,
              'text--lime': percent >= 25 && percent < 50,
              'text--warning': percent >= 50 && percent < 75,
              'text--error': percent >= 75 && voxelGroup[property] !== min,
            };

          default:
            return {
              'text--info': voxelGroup[property] === max,
              'text--success': percent >= 75 && voxelGroup[property] !== max,
              'text--lime': percent >= 50 && percent < 75,
              'text--warning': percent >= 25 && percent < 50,
              'text--error': percent < 25 && voxelGroup[property] !== max,
            };
        }
      },
      async refreshVoxelGroup() {
        this.loading = true;
        await apiSecured.get('/ingame/items/group/by-key/Honeycomb').then((res) => {
          this.voxelGroup = res.data;
          this.loading = false;
          this.showInfoNotification('Voxel group loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async refreshSubGroups() {
        this.loading = true;
        const params = {
          parentIds: this.voxelGroup.id,
          pageSize: -1,
          fields: 'id,name,key,parentId,icon'
        };
        await apiSecured.get('/ingame/items/group', { params }).then((res) => {
          this.subGroups = res.data.sort((a, b) => a.name > b.name ? 1 : -1);
          this.loading = false;
          this.showInfoNotification('Subgroups loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async refreshVoxelGroups() {
        this.loading = true;
        const params = {
          parentIds: this.subGroups.map(subgroup => subgroup.id).join(','),
          pageSize: -1,
          fields: 'id,name,key,parentId,icon'
        };
        await apiSecured.get('/ingame/items/group', { params }).then((res) => {
          this.voxelGroups = res.data;
          this.loading = false;
          this.showInfoNotification('Voxel groups loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async refreshVoxels() {
        this.loading = true;
        const params = {
          groupIds: this.voxelGroup.id,
          recursiveGroupsFilter: true,
          pageSize: -1,
          fields: [
            'id',
            'name',
            'key',
            'groupId',
            'icon',
            'tier',
            'craftingTime',
            'hitpoints',
            'mass',
            'resistancesAntimatter',
            'resistancesElectromagnetic',
            'resistancesKinetic',
            'resistancesThermic',
            'resistancesAntimatterHp',
            'resistancesKineticHp',
            'resistancesThermicHp',
            'resistancesElectromagneticHp',
            'resistancesAntimatterHpPerMass',
            'resistancesKineticHpPerMass',
            'resistancesThermicHpPerMass',
            'resistancesElectromagneticHpPerMass'
          ].join(',')
        };
        await apiSecured.get('/ingame/items/item', { params }).then((res) => {
          this.voxels = res.data;
          this.loading = false;
          this.showInfoNotification('Voxels loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async refresh() {
        this.properties.forEach(property => {
          this.values[property] = [];
        });
        this.colors = [];
        await this.refreshVoxelGroup();
        await this.refreshSubGroups();
        await this.refreshVoxelGroups();
        await this.refreshVoxels();

        const colors = [];
        this.voxelGroups = this.voxelGroups.sort((a, b) => a.name > b.name ? 1 : -1).map(voxelGroup => {
          voxelGroup.voxels = this.voxels.filter(voxel => voxel.groupId === voxelGroup.id);
          this.properties.forEach(property => {
            voxelGroup[property] = voxelGroup.voxels[0][property];
            this.values[property].push(voxelGroup[property]);
          });

          let material = voxelGroup.name.replace(' Honeycomb','');
          material = material.charAt(0).toUpperCase() + material.slice(1);

          voxelGroup.voxels.forEach(voxel => {
            let color = voxel.name.replace(material, '');
            colors.push(color);
          });
          return voxelGroup;
        });

        this.colors = [...(new Set(colors))].sort((a, b) => a > b ? 1 : -1);
      }
    },
    created() {
      this.setPageTitle('Voxel ' + this.__('library'));
      this.refresh();
    }
  }
</script>
